<template>
  <a-form-model
    style="background: #ffffff;min-height:900px;padding-top: 20px;"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <!--    <a-form-model-item v-if="id == 0" label="所属区域：" prop="province">-->
    <!--      &lt;!&ndash; <a-cascader-->
    <!--        :options="options"-->
    <!--        :load-data="loadData"-->
    <!--        placeholder="请选择所属区域"-->
    <!--        change-on-select-->
    <!--        @change="onChange"-->
    <!--      /> &ndash;&gt;-->
    <!--      <a-select-->
    <!--        label-in-value-->
    <!--        placeholder="请选择省"-->
    <!--        style="width: 143px;margin-right:20px"-->
    <!--        @change="handleChange"-->
    <!--        :disabled="form.province_able"-->
    <!--      >-->
    <!--        <a-select-option :value="item.value" v-for="(item,index) in options" :key="index" :title="item.name">-->
    <!--          {{ item.name }}-->
    <!--        </a-select-option>-->
    <!--      </a-select>-->
    <!--      <a-select-->
    <!--        label-in-value-->
    <!--        placeholder="请选择市"-->
    <!--        style="width: 143px;margin-right:20px"-->
    <!--        @change="handleChange2"-->
    <!--        :disabled="form.city_able"-->
    <!--      >-->
    <!--        <a-select-option :value="item.value" v-for="(item,index) in twoOptions" :key="index">-->
    <!--          {{ item.name }}-->
    <!--        </a-select-option>-->
    <!--      </a-select>-->
    <!--      <a-select-->
    <!--        label-in-value-->
    <!--        placeholder="请选择区"-->
    <!--        style="width: 143px"-->
    <!--        @change="handleChange3"-->
    <!--        :disabled="form.area_able"-->
    <!--      >-->
    <!--        <a-select-option :value="item.value" v-for="(item,index) in threeOptions" :key="index">-->
    <!--          {{ item.name }}-->
    <!--        </a-select-option>-->
    <!--      </a-select>-->
    <!--    </a-form-model-item>-->
    <a-form-model-item label="所属区域：" prop="province">
      <!-- <a-cascader
        :options="options"
        :load-data="loadData"
        placeholder="请选择所属区域"
        change-on-select
        @change="onChange"
      /> -->
      <a-select
        label-in-value
        :value="{ key: form.province_id }"
        placeholder="请选择省"
        style="width: 143px;margin-right:20px"
        @change="handleChange"
        :disabled="form.province_able"
      >
        <a-select-option
          :value="item.value"
          v-for="(item, index) in options"
          :key="index"
          :title="item.name"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-select
        label-in-value
        :value="{ key: form.city_id }"
        placeholder="请选择市"
        style="width: 143px;margin-right:20px;"
        @change="handleChange2"
        :disabled="form.city_able"
      >
        <a-select-option
          :value="item.value"
          v-for="(item, index) in twoOptions"
          :key="index"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-select
        label-in-value
        :value="{ key: form.area_id }"
        placeholder="请选择区"
        style="width: 143px;"
        @change="handleChange3"
        :disabled="form.area_able"
      >
        <a-select-option
          :value="item.value"
          v-for="(item, index) in threeOptions"
          :key="index"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item ref="name" label="组织名称：" prop="name">
      <a-input
        placeholder="请输入组织名称"
        v-model="form.name"
        :disabled="show == 1 ? true : false"
        @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item label="组织类型：" prop="type">
      <a-input
        placeholder="请输入组织类型"
        v-model="form.type"
        :disabled="show == 1 ? true : false"
      />
    </a-form-model-item>
    <a-form-model-item ref="describe" label="组织描述：" prop="describe">
      <a-input
        placeholder="请输入组织描述"
        v-model="form.describe"
        :disabled="show == 1 ? true : false"
        @blur="
          () => {
            $refs.describe.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{ span: 8, offset: 8 }"
      style="text-align: center;"
    >
      <a-button type="primary" @click="onSubmit" v-if="!id && !show">
        立即创建
      </a-button>
      <a-button type="primary" @click="onSubmit" v-if="id && !show">
        立即修改
      </a-button>
      <a-button style="margin-left: 10px;" @click="resetForm">
        取消
      </a-button>
      <!--      <a-button style="margin-left: 10px;" @click="resetForm">-->
      <!--        返回-->
      <!--      </a-button>-->
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import {
  createDept,
  showDept,
  editDept,
  getAreas
} from "@/api/permission/organization/index";
export default {
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      other: "",
      //省市区选择
      options: [
        // {
        //   value: 'zhejiang',
        //   label: 'Zhejiang',
        //   isLeaf: false,
        // },
        // {
        //   value: 'jiangsu',
        //   label: 'Jiangsu',
        //   isLeaf: false,
        // },
      ],
      twoOptions: [],
      threeOptions: [],
      form: {
        region: undefined,
        province_id: "",
        province: "",
        province_able: false,
        city_id: "",
        city: "",
        city_able: false,
        area_id: "",
        area: "",
        area_able: false,
        name: "",
        type: undefined,
        describe: ""
      },
      rules: {
        province: [
          { required: true, message: "请选择所属区域", trigger: "change" }
        ],
        name: [{ required: false, message: "请输入姓名", trigger: "blur" }],
        type: [{ required: false, message: "请选择性别", trigger: "change" }],
        describe: [
          { required: false, message: "请输入组织描述", trigger: "blur" }
        ]
      },
      id: 0,
      show: 0
    };
  },
  async created() {
    await this.getAreaList({
      id: 0
    });
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      showDept({
        deptId: this.id
      }).then(res => {
        if (res.code == 200) {
          if (res.data.province) {
            this.form.province_id = parseInt(res.data.province);
            this.form.province = res.data.province_name;
            this.getCities();
          }
          if (res.data.city) {
            this.form.city_id = parseInt(res.data.city);
            this.form.city = res.data.city_name;
            this.getAreas();
          }
          if (res.data.area) {
            this.form.area_id = parseInt(res.data.area);
            this.form.area = res.data.area_name;
          }
          this.form.name = res.data.dept_name;
          this.form.type = res.data.dept_type;
          this.form.describe = res.data.remark;
        }
      });
    }
    if (this.$route.query.show) {
      this.show = this.$route.query.show;
      this.form.province_able = true;
      this.form.city_able = true;
      this.form.area_able = true;
    }
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.form.province_id) {
            this.$message.error("请填写所属区域");
            return;
          }
          if (this.id) {
            this.updateDept();
          } else {
            this.addDept();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取省列表
    async getAreaList(param) {
      let res = await getAreas(param);
      if (res.code == 200) {
        //
        for (let i in res.data.list) {
          let data = res.data.list[i];
          this.options.push({
            value: data.id,
            name: data.name
          });
        }

        //  验证用户身份
        let admin = localStorage.getItem("admin");
        // 非管理员
        if (admin == "0") {
          let province = JSON.parse(localStorage.getItem("province"));
          if (province.id) {
            this.form.province_able = true;
            this.form.province_id = parseInt(province.id);
            this.form.province = province.name;
            this.getCities();
          }
          let city = JSON.parse(localStorage.getItem("city"));
          if (city.id) {
            this.form.city_able = true;
            this.form.city_id = parseInt(city.id);
            this.form.city = city.name;
            this.getAreas();
          }
          let area = JSON.parse(localStorage.getItem("area"));
          if (area.id) {
            this.form.area_able = true;
            this.form.area_id = parseInt(area.id);
            this.form.area = area.name;
          }
        }
      }
    },
    // 获取市列表
    getCities() {
      getAreas({
        id: this.form.province_id
      }).then(res => {
        if (res.code == 200) {
          this.twoOptions = [];
          for (let i in res.data.list) {
            let data = res.data.list[i];
            this.twoOptions.push({
              value: data.id,
              name: data.name
            });
          }
        }
      });
    },
    // 获取区、县列表
    getAreas() {
      getAreas({
        id: this.form.city_id
      }).then(res => {
        if (res.code == 200) {
          this.threeOptions = [];
          for (let m in res.data.list) {
            let data = res.data.list[m];
            this.threeOptions.push({
              value: data.id,
              name: data.name
            });
          }
        }
      });
    },
    addDept() {
      let _this = this;
      createDept({
        province: _this.form.province_id,
        province_name: _this.form.province,
        city: _this.form.city_id,
        city_name: _this.form.city,
        area: _this.form.area_id,
        area_name: _this.form.area,
        dept_name: _this.form.name,
        dept_type: _this.form.type,
        remark: _this.form.describe
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          _this.$message.success("创建成功", function() {
            _this.$router.push({
              path: "/index/auth/organization/organizationList"
            });
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    updateDept() {
      let _this = this;
      editDept({
        dbId: _this.id,
        province: _this.form.province_id,
        province_name: _this.form.province,
        city: _this.form.city_id,
        city_name: _this.form.city,
        area: _this.form.area_id,
        area_name: _this.form.area,
        dept_name: _this.form.name,
        dept_type: _this.form.type,
        remark: _this.form.describe
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          _this.$message.success("编辑成功", function() {
            _this.$router.push({
              path: "/index/auth/organization/organizationList"
            });
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.push({ path: "/index/auth/organization/organizationList" });
    },

    onChange(value) {
      console.log(value);
    },
    // 省市区
    handleChange(value) {
      console.log(value);
      if (value) {
        this.form.province_id = value.key;
        this.form.province = value.label;
        console.log(this.form);
        // this.selected_pid = value;
        // for (let i in this.options) {
        //   if (this.options[i].value == value) {
        //     this.form.province = this.options[i].name;
        //     break;
        //   }
        // }
        getAreas({
          id: value.key
        }).then(res => {
          if (res.code == 200) {
            this.form.city_id = '';
            this.form.area_id = '';
            this.twoOptions = [];
            for (let i in res.data.list) {
              let data = res.data.list[i];
              this.twoOptions.push({
                value: data.id,
                name: data.name
              });
            }
          }
        });
      }
    },
    handleChange2(value) {
      // console.log(`selected ${value}`);
      if (value) {
        this.form.city_id = value.key;
        this.form.city = value.label;
        // this.selected_cid = value;
        // for (let i in this.twoOptions) {
        //   if (this.twoOptions[i].value == value) {
        //     this.form.city = this.twoOptions[i].name;
        //     break;
        //   }
        // }
        getAreas({
          id: value.key
        }).then(res => {
          if (res.code == 200) {
            this.form.area_id = '';
            this.threeOptions = [];
            for (let i in res.data.list) {
              let data = res.data.list[i];
              this.threeOptions.push({
                value: data.id,
                name: data.name
              });
            }
          }
        });
      }
    },
    handleChange3(value) {
      // console.log(value);
      if (value) {
        this.form.area_id = value.key;
        this.form.area = value.label;
        // this.selected_did = value;
        // for (let i in this.threeOptions) {
        //   if (this.threeOptions[i].value == value) {
        //     this.form.area = this.threeOptions[i].name;
        //     break;
        //   }
        // }
      }
    }
  }
};
</script>

<style scoped></style>
